body{
  background-color: black;
  overflow: hidden;
  /* background-image: linear-gradient(45deg, #1234,#5432); */
  color: #fff;

}

html, body {
  touch-action: none;
  -ms-touch-action: none;
}

::-webkit-scrollbar{
  display: none;
}

ion-icon {
  font-size: 18px;
  color: #ffffff50;
}

*{
  outline: none;
  /* overflow: hidden; */
}

#root{
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}