
  

  
  .container {
    text-align: center;
    margin: 50px auto;
    max-width: 800px;
    height: 50vh;
  }
  
  .movies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    justify-items: center;
    height: calc(90vh - 150px);
    overflow: scroll;
  }
  
  .movie-item {
    position: relative;
    width: 150px;
    height: 225px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 20px;
    overflow: hidden;
  }
  
  .movie-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
  
  .movie-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 8px;
    transition: opacity 0.3s ease;
  }
  
  .movie-item.selected {
    border-color: #fff;
  }
  
  .movie-item.selected .overlay {
    opacity: 1;
  }
