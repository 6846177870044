.mobile-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .add-to-homescreen-instructions {
    margin-top: 20px;
  }
  
  ol {
    text-align: left;
    margin: 0 auto;
    max-width: 300px;
  }
  