body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loginContainer{
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  background: linear-gradient(270deg, #281166, #000000);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 11s ease infinite;
  -moz-animation: AnimationName 11s ease infinite;
  animation: AnimationName 11s ease infinite;
}



@-webkit-keyframes AnimationName {
  0%{background-position:0% 49%}
  50%{background-position:100% 52%}
  100%{background-position:0% 49%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 49%}
  50%{background-position:100% 52%}
  100%{background-position:0% 49%}
}
@keyframes AnimationName {
  0%{background-position:0% 49%}
  50%{background-position:100% 52%}
  100%{background-position:0% 49%}
}

.box {
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #000; /* Background color of the box */
  padding: 20px;
  border-radius: 20px;
  border: 1px solid transparent; /* Transparent border */
  background-clip: padding-box, border-box; /* Ensure the background-clip works for both the border and box */
  background-origin: border-box; /* Make the background origin for the gradient border */
  animation: anim 5s infinite linear;
  background-size: 500% 100%;
  background-image: linear-gradient(#000000, #000000), linear-gradient(-45deg, #00000010 0% 45%, #ffffff20 49% 50%, #00000010 55% 100%);

      z-index: 1;
}

.animate-border {
  position: relative; /* Ensures the pseudo-element is positioned relative to the element */
  padding: 2px;
  z-index: 1;
}

.animate-border::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  background-image: linear-gradient(#000, #000), 
                    linear-gradient(-45deg, #1234 0% 45%, #2345 49% 50%, #1234 55% 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 500% 100%;
  animation: anim 5s linear infinite;
  z-index: -1; /* Ensures it’s behind the content */
  border-radius: 100px; /* Optional: Gives rounded corners */
}


@keyframes anim {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -250% 0%;

  }
}


.box input{
  border: none;
  background-color: #ffffff10;
  font-size: 11px;
  border-radius: 20px;
  padding: 10px 20px;
  outline: none;
  color: #fff;
  flex: 1;
}

.inpuDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.plinxLogo{
  width: 100px;
  margin: 20px 100px 50px 100px;
}

.buttonDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.loginButton{
  border: none;
  background-color: #ffffff;
  font-size: 11px;
  border-radius: 20px;
  padding: 10px 20px;
  outline: none;
  color: #000;
  flex: 1;
  cursor: pointer;
}

.signupButton{
  border: none;
  background-color: #ffffff10;
  font-size: 11px;
  border-radius: 20px;
  padding: 10px 20px;
  outline: none;
  color: #fff;
  flex: 1;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.signupButton:active, .loginButton:active {
  opacity: 0.5;
}

.extraLink{
  display: flex;
  margin-top: 20px;
}
.extraLink a{
  color: #ffffff;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  margin-left: auto;
}

.movieGridItem{
  min-height: 152px;
  min-width: 300px;
  background-color: #000;
  margin: 2px;
  transition: 0.2s;
  position: relative;
}

.movieGridItem:hover {
  cursor: pointer;
  z-index: 10;
  transform: perspective(600px) translate3d(0px, 0px, 100px);
  box-shadow: -20px 20px 20px 5px rgba(0, 0, 0, 0.9);
}

.movieGrid {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* z-index: -1; */
  width: 300vw;
  height: 300vh;
  overflow-x: scroll;
  transform:
  perspective(600px)
  scale(0.7)
  /* rotate3d(1, 1, 0, 10deg) */
  rotateY(10deg)
  rotateX(10deg)
  translate(10%, 0px);
  ;

  background-color: #000;
  /* animation: anim 10s infinite linear; */
  /* background-size: 500% 100%;
  background-image: linear-gradient(-45deg, #00000010 0% 47%, #ffffff50 49% 50%, #00000010 52% 100%); */
  /* filter: blur(2px); */
  opacity: 0.2;
}


.movieRow{
  display: flex;
  position: relative;
}

.movieRow:hover{
  z-index: 10;
}

@keyframes goright {
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0%);
  }
}

@keyframes goleft {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-100%);
  }
}




/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #1234;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 90vh;
  height: 90vh;
  border: 1px solid #ffffff10;
  /* padding: 0px 20px; */
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 80px);
  /* For mobile devices (up to 767px wide) */
  @media screen and (max-width: 767px) {
    /* Mobile-specific styles */
    max-width: calc(100%);
    flex: 1;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    border: none;
    /* padding: 5px; */
  }
}


.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  background-color: #ffffff10;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 50px;
  padding: 5px;
  margin: 10px;
  z-index: 100;
}

.modal-content {
  text-align: center;
  overflow-y: scroll;
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 11px;

  @media screen and (max-width: 767px) {
    padding: 10px;
  }
}



.content-thumbnail{
  height: 300px;
  min-height: 300px;
  background-color: #ffffff10;
  border-radius: 20px;
  overflow: hidden;
  font-size: 11px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content-thumbnail .logo{
  height: 100px;
  width: 200px;
  margin-right: auto;
  object-fit: contain;
}

.seekbar:hover{
  transform: scaleY(2);
}

.seekbar{
  transition: 0.1s;
}






.row_column{
  flex-direction: row;
  justify-content: center;
}


/* For mobile devices (up to 767px wide) */
@media screen and (max-width: 767px) {
  /* Mobile-specific styles */

  .row_column{
    flex-direction: column;
  }
}

/* For tablets (768px to 1024px wide) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Tablet-specific styles */



}
