
/* Header styles */

.Header{
    height: 50px;
    width: calc(100vw - 40px);
    background-color: #ffffff00;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 5px 20px;
    gap: 50px;
    font-size: 11px;
    position: fixed;
    z-index: 10;
}

.searchContainer{
    width: 210px;
    left: 20px;
    top: 28px;
    position: absolute;
    transition: 0.2s;
    z-index: 1;
}

.searchInput:hover ~ .result{
    display: none;
}

.search{
    height: 35px;
    min-width: 170px;
    width: min-content;
    background-color: #ffffff10;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 20px;
    cursor: text;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.blur{
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.searchInput:focus + .searchContainer{
    width: 500px;
    left: calc(50% - 250px);
    top: 78px;
    bottom: 100px;
}

/* .searching{
    width: 500px;
    left: calc(50% - 250px);
    top: 78px;
    bottom: 100px;
} */

.searchInput{
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    flex: 1;
}

.result{
    border-radius: 20px;
    background-color: #1234;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-top: 20px;
    max-height: 75vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.result-item{
    padding: 10px;
    display: flex;
    gap: 10px;
    color: #fff;
    text-decoration: none;
}

.result-item:hover{
    border-radius: 20px;
    background-color: #fff1;
}

.shortOverview {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mediumOverview {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    max-height: 34px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pageLinks{
    height: 35px;
    flex: 1;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.menu{
    margin-right: auto;
    margin-left: 260px;
}

.pageLink{
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 20px;
    color: #fff;
    font-weight: 800;
    text-decoration: none;
    cursor: pointer;
}

.pageLink:hover{
    background-color: #ffffff08;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
}

.pageLink.active{
    background-color: #ffffff10;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
}

.userArea{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 28px;
    color: #fff;
    font-weight: 800;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
    position: fixed;
    right: 20px;
    top: 25px;
    z-index: 100;
}

.usersList{
    flex-direction: column;
    background-color: #ffffff10;
    border-radius: 20px;
    display: none;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
}

.username{
    margin-right: auto;
}

.user{
    padding: 5px 20px 5px 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 28px;
    color: #fff;
    font-weight: 800;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
}

.user:hover{
    background-color: #ffffff10;
}

.userArea:hover .usersList{
    display: flex;
}

.expandUser ion-icon{
    transition: 0.5s;
}

.expandUser:hover ion-icon{
    transform: rotateX(180deg);
}

.pfp{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    aspect-ratio: 1;
    border-radius: 20px;
    color: #fff;
    font-weight: 800;
    text-decoration: none;
    background-color: #ffffff10;
    background-position: center;
    background-size: cover;
}

/* Skeleton styles */

.Skeleton{
    display: flex;
    height: calc(100vh);
    gap: 20px;
}

.pannels{
    border-radius: 20px;
}

.left {
    flex: 0.5;
    background-color: #ffffff10;
}

.right {
    flex: 2;
    display: flex;
    flex-direction: column;
    background-color: #ffffff00;
    gap: 20px;
    padding-bottom: 100px;
}

.large {
    flex: 1;
}

.animateFlat{
    animation: animateFlat 5s infinite linear; /* Smoother animation */
}


.colum, .column{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.row {
    display: flex;
    gap: 10px;
}

.s_card{
    background-color: #ffffff10;
    height: 150px;
    min-width: 270px;

    border-radius: 20px;

}

.RowTitle{
    font-size: 18px;
    margin-right: auto;
}

.scroll{
    overflow-y: scroll;
}

.largeBackdrop{
    min-height: 400px;
    height: 400px;
    background-image: url(https://image.tmdb.org/t/p/original/Asg2UUwipAdE87MxtJy7SQo08XI.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 50px;
    margin: 10px;
}



.button{
    background-color: #ffffff10;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);

}

.nbbutton{
    background-color: #ffffff00;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.button.square, .nbbutton.square{
    padding: 10px 10px;
}

.button ion-icon, .nbbutton ion-icon{
    color: white;
}

.button:hover, .button:hover ion-icon, .nbbutton:hover, .nbbutton:hover ion-icon{
    color: #000;
    background-color: #fff;
    cursor: pointer;

}

.likeButton, .shareButton {
    transform: scale(0);
    transition: transform 0.1s ease; /* Optional: smooth transition */
}

.expandable{
    width: 38px;
    transform: 0.1s;
}

.expandable:hover{
    width: auto;
}

.expandable:hover button{
    transform: scale(1);
}

.expandable:hover button:hover{
    transition: transform 0.1s;
    transition-delay: 0s;
    transform: scale(1.1);
}


.expandable:hover button:active{
    transition: transform 0.05s;
    transition-delay: 0s;
    transform: scale(1);
}

.shareButton{
    transition-delay: 0.05s;
}

.largeBackdrop .logo{
    height: 100px;
    width: 300px;

    object-fit: contain;
}

.largeBackdrop .overview{
    color: #fff;
    width: 40%;
    padding: 20px 0px;
    font-size: 11px;
}

.card{
    cursor: pointer;
    transition: 0.1s;
    border: 2px solid transparent;
    background-position: center;
    background-size: calc(100% + 4px);
    min-width: 150px;
    min-height: 225px;
    border-radius: 20px;

    @media screen and (max-width: 767px) {
        min-width: 100px;
        min-height: 150px;
        border-radius: 10px;
    }
}

.card:hover{
    /* transform: scale(1.02); */
    border: 2px solid #ffffff50;
}

.one{
    background-image: url(https://image.tmdb.org/t/p/original/f6TCICUC8OSBtZDKgg18T6PjfIM.jpg);
}

.two{
    background-image: url(https://image.tmdb.org/t/p/original/Asg2UUwipAdE87MxtJy7SQo08XI.jpg);
}
.three{
    background-image: url(https://image.tmdb.org/t/p/original/cO7Mhi8GmtVH4pgZMMqSumOLrC4.jpg);
}
.four{
    background-image: url(https://image.tmdb.org/t/p/original/yNU8UF3DOmv3G9gVNAj34beclTG.jpg);
}

@keyframes animateFlat {
    0% {
        background-color: transparent;
        background-image: linear-gradient(-45deg, #1234 0% 45%, #2344 49% 50%, #1234 55% 100%);
        background-size: 500% 100%; /* Allows the gradient to animate smoothly */
        background-position: 0% 0%;
    }
    50% {
        background-color: transparent;
        background-image: linear-gradient(-45deg, #1234 0% 45%, #2344 49% 50%, #1234 55% 100%);
        background-size: 500% 100%; /* Allows the gradient to animate smoothly */
        background-position: -125% 0%;
    }
    100% {
        background-color: transparent;
        background-image: linear-gradient(-45deg, #1234 0% 45%, #2344 49% 50%, #1234 55% 100%);
        background-size: 500% 100%; /* Allows the gradient to animate smoothly */
        background-position: -250% 0%;
    }
}


/* @keyframes animateFlat {
    0% {
        background-color: #ffffff10;
    }
    50% {
        background-color: #ffffff20;
    }
    100% {
        background-color: #ffffff10;
    }
} */


.bottomNavbar{
    position: absolute;
    z-index: 1;
    height: 60px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #0005;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding-bottom: 30px;
    display: flex;
    border-top: 1px solid #fff2;
}

.bottomNavbar div button{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000;
    border: none;
    padding: 10px;
}

.bottomNavbar div button ion-icon{
    font-size: 24px;
    color: #fff;
}


.menuToggle{
    display: none;
}


.blur{
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.outline-button{
    border-radius: 50px;
    color: #fff;
    border: 1px solid #fff5;
    background-color: #fff0;
    padding: 5px 20px;
    font-weight: bold;
}

.avatar{
    border-radius: 50px;
    background-size: cover;
    height: 100px;
    width: 100px;
}

.avatar.medium{
    height: 45px;
    width: 45px;
}


/* For mobile devices (up to 767px wide) */
@media screen and (max-width: 767px) {
    /* Mobile-specific styles */


    .Header{
        background-color: #55555550;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
    }

    .mobileCenter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .right{
        padding: 0px 10px 100px 10px;
        height: calc(100vh - 100px);
    }

    .Skeleton{
        padding: 0px;
    }


    .left{
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        /* height: 100px; */
        aspect-ratio: 4/1;
        z-index: 10;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    }

    .overview{
        display: none;
    }
    .largeBackdrop{
        flex: unset;
        width: 250px;
        align-items: center;
        padding: 0px;
        margin: 0px auto;
    }

    .largeBackdrop .logo{
        width: 150px;
        margin-top: 200px;
    }

    .search{
        display: none;
    }
    
    .pageLinks{
        display: none;
        margin-top: 25px;
        flex-direction: column;
        background-color: #ffffff10;
        height: unset;
        padding: 0px;
        align-items: unset;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(10px);
        gap: 0px;
    }
    
    .menu{
        position: absolute;
        top: 28px;
        margin-left: 0px;
    }

    .menuToggle{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .menu:hover .pageLinks{
        display: flex;
    }
  }
  
  /* For tablets (768px to 1024px wide) */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    /* Tablet-specific styles */

    .searchContainer{
        width: 58px;
    }

    .mobileCenter{
        display: flex;
    flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .overview{
        display: none;
    }
    .largeBackdrop{
        flex: unset;
        width: 400px;
        align-items: center;
        padding: 0px;
        margin: 0px auto;
    }

    .largeBackdrop .logo{
        width: 150px;
        margin-top: 200px;
    }

    .searchInput{
        display: none;
    }

    .search {
        min-width: 0;
    }

    .menu{
        margin-left: 100px;
    }

  }
  